// noinspection TypeScriptRedundantGenericType

import * as JsBarcode from 'jsbarcode';
import {BaseOptions, Ean13Options} from 'jsbarcode';
import {EanBarcode, Form} from '..';
import {EanDefaultMagazineCodes} from '.';

export class EanDefault implements EanBarcode {

  private readonly magazine: JQuery<HTMLSelectElement>;
  protected readonly magazineOther: JQuery<HTMLInputElement>;
  protected readonly magazineOtherBox: JQuery<HTMLSpanElement>;
  protected readonly tidsam: JQuery<HTMLInputElement>;
  protected readonly issue: JQuery<HTMLInputElement>;
  protected readonly price: JQuery<HTMLInputElement>;
  protected readonly priceOre: JQuery<HTMLInputElement>;

  constructor(
    private form: Form,
    protected preview: HTMLElement,
  ) {
    this.magazine = form.get('#magazine');
    this.magazineOther = form.get('#magazine_other');
    this.magazineOtherBox = form.get('#magazine_other_box');
    this.tidsam = form.get('#tidsam');
    this.issue = form.get('#issue');
    this.price = form.get('#price');
    this.priceOre = form.get('#price_ore');

    console.log('EanDefault constructor');
    this.init();
  }

  public validate(): boolean {
    const magazineVal = this.magazine.find(":selected").val() as string;
    const magazineOtherVal = this.magazineOther.val() as string;
    const issueVal = this.issue.val() as string;
    const priceVal = this.price.val() as string;
    const priceOreVal = this.priceOre.val() as string;

    return this.form.mark(this.magazine, magazineVal === '' && magazineOtherVal === '')
      && this.form.mark(this.magazineOther, magazineVal === '' && magazineOtherVal.length !== 4)
      && this.form.mark(this.issue, issueVal.length !== 2)
      && this.form.mark(this.price, priceVal.length !== 3)
      && this.form.mark(this.priceOre, priceOreVal.length !== 2 || parseInt(priceOreVal) % 10 !== 0);
  }

  public paint() {
    const magazine = this.magazine.find(":selected").val().toString() || this.magazineOther.val();
    const issue = this.issue.val();
    const price = this.price.val() + this.priceOre.val().substring(0, 1);
    const tidsam = this.tidsam.get(0).checked;

    const text = `7388${magazine}${price}`;
    const font = this.getFontName();

    try {
      JsBarcode(this.preview)
        .EAN13(text, this.getBarcodeTextOptions(font))
        .blank(8)
        .EAN2(issue, this.getRightTextOption(font))
        .render();
    } catch (e) {
      console.log(e);
    }

    if (tidsam) {
      const svgns = "http://www.w3.org/2000/svg";

      const textElem = window.document.createElementNS(svgns, 'text');
      this.setTopTextStyle(textElem);
      textElem.appendChild(window.document.createTextNode(`TIDSAM ${magazine}-${issue}`));

      this.preview.appendChild(textElem);
    }
  }

  public getBarcodeTextOptions(font: string): Ean13Options {
    return {marginTop: 30, font, height: 83};
  }

  public getRightTextOption(font: string): BaseOptions {
    return {marginTop: 28, height: 60, textPosition: "top", textMargin: 5, fontSize: 20, font};
  }

  public setTopTextStyle(text: SVGTextElement): void {
    const font = this.getFontName();

    text.setAttribute("style", `font-family:'${font}';font-size: 20px;font-weight:normal`);
    text.setAttribute("x", '15');
    text.setAttribute("y", '20');
  }

  public getFilename(): string {
    return `barcode-${this.magazine.val()}-${this.issue.val()}.pdf`;
  }

  public getFontName(): string {
    return 'CourierNew';
  }

  public getPdfSize(): [number, number] {
    return [222, 122];
  }

  private init() {
    this.form.init(this);

    const sortedCodes = Object.entries(EanDefaultMagazineCodes)
      .sort((a, b) => a[1].localeCompare(b[1], this.form.getSortLocale()));

    for (const [key, value] of sortedCodes) {
      this.magazine.append(`<option value="${key}">${key} - ${value}</option>`);
    }

    this.magazine.on('change', (): void => {
      this.form.markValid(this.magazine, this.magazineOther);
      const val = this.magazine.find(":selected").val();

      if (val === '') {
        this.magazineOtherBox.css('visibility', 'visible');
      } else {
        this.magazineOtherBox.css('visibility', 'hidden');
      }
    });

    this.form.limitToChars(this.magazineOther, 4);
    this.magazineOther.on('change', () => this.form.markValid(this.magazineOther, this.magazine));

    this.form.limitToChars(this.issue, 2);
    this.issue.on('change', () => {
      this.form.fixLeadingZero(this.issue);
      this.form.markValid(this.issue)
    });

    this.form.limitToChars(this.price, 3);
    this.price.on('change', () => {
      this.form.fixLeadingZero(this.price, 2);
      this.form.markValid(this.price)
    });

    this.form.limitToChars(this.priceOre, 2);
    this.priceOre.on('change', () => {
      this.form.fixTrailingZero(this.priceOre);
      this.form.markValid(this.priceOre)
    });

    console.log('is debug', this.form.isDebug());

    if (this.form.isDebug()) {
      this.applyDebugValues();
      this.validate();
      this.paint();
    }
  }

  public applyDebugValues(): void {
    this.magazineOther.val('0574');
    this.issue.val('12');
    this.price.val('123');
    this.priceOre.val('90');
    this.tidsam.get(0).checked = true;
  }
}
