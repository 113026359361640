// noinspection TypeScriptRedundantGenericType

import * as JsBarcode from 'jsbarcode';
import {BaseOptions, Ean13Options} from 'jsbarcode';
import {EanBarcode, Form} from '..';

export class IsbnKids implements EanBarcode {

  protected readonly code: JQuery<HTMLInputElement>;
  protected readonly isbn: JQuery<HTMLInputElement>;

  constructor(
    private form: Form,
    protected preview: HTMLElement,
  ) {
    this.code = form.get('#code');
    this.isbn = form.get('#isbn');

    this.init();
  }

  public validate(): boolean {
    const codeVal = this.code.val() as string;

    return this.form.mark(this.code, codeVal.length !== 12);
  }

  public paint() {
    const text = this.code.val();
    const prefix = text.substring(0, 3);
    const country = text.substring(3, 5);
    const publisher = text.substring(5, 9);
    const title = text.substring(9, 12);
    const isbn = this.isbn.get(0).checked;

    const font = this.getFontName();

    const parity = this.getParity(text);

    const barcode = JsBarcode(this.preview);

    try {
      barcode
        .EAN13(text, this.getBarcodeTextOptions(font))
        .render();
    } catch (e) {
      console.log(e);
    }

    if (isbn) {
      const textElem = this.createTextElement(`ISBN: ${prefix}-${country}-${publisher}-${title}-${parity}`);
      this.setTopTextStyle(textElem);

      this.preview.appendChild(textElem);
    }
  }

  private getParity(text: string): string {
    let sum = 0;
    for (let i = 0; i < text.length; i++) {
      const digit = parseInt(text[i]);
      sum += digit * (i % 2 === 0 ? 1 : 3);
    }

    const parity = (10 - sum % 10) % 10;

    return parity.toString();
  }

  private createTextElement(text: string): SVGTextElement {
    const textNode = window.document.createTextNode(text);

    const svgns = "http://www.w3.org/2000/svg";
    const textElem = window.document.createElementNS(svgns, 'text');
    textElem.appendChild(textNode);

    return textElem;
  }

  public getBarcodeTextOptions(font: string): Ean13Options {
    return {marginLeft: 30, marginTop: 28, marginRight: 60, textMargin: -2, fontSize: 23, font, height: 53};
  }

  public getRightTextOption(font: string): BaseOptions {
    return {marginTop: 28, height: 60, textPosition: "top", textMargin: 5, fontSize: 20, font};
  }

  public setTopTextStyle(text: SVGTextElement): void {
    const font = this.getFontName();

    text.setAttribute("style", `font-family:'${font}';font-size: 17px;font-weight:normal`);
    text.setAttribute("x", '35');
    text.setAttribute("y", '20');
  }

  public getFilename(): string {
    return `barcode-${this.code.val()}.pdf`;
  }

  public getFontName(): string {
    return 'OpenSans';
  }

  public getPdfSize(): [number, number] {
    return [183, 86];
  }

  private init() {
    this.form.init(this);

    this.code.on('change', (): void => this.form.markValid(this.code));
    this.form.limitToChars(this.code, 12);

    if (this.form.isDebug()) {
      this.applyDebugValues();
      this.validate();
      this.paint();
    }
  }

  public applyDebugValues(): void {
    this.code.val('123456789012');
    this.isbn.get(0).checked = true;
  }
}
