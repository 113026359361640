// noinspection TypeScriptRedundantGenericType

import * as JsBarcode from 'jsbarcode';
import {BaseOptions, Ean13Options} from 'jsbarcode';
import {EanBarcode, Form} from '..';

export class EanKarnan implements EanBarcode {

  protected readonly code: JQuery<HTMLInputElement>;

  constructor(
    private form: Form,
    protected preview: HTMLElement,
  ) {
    this.code = form.get('#code');

    this.init();
  }

  public validate(): boolean {
    const codeVal = this.code.val() as string;

    return this.form.mark(this.code, codeVal.length !== 12);
  }

  public paint() {
    const text = this.code.val();
    const font = this.getFontName();
    const barcode = JsBarcode(this.preview);

    try {
      barcode
        .EAN13(text, this.getBarcodeTextOptions(font))
        .render();
    } catch (e) {
      alert('Could not generate barcode: ' + e.message);
      console.log(e);
    }
  }

  public getBarcodeTextOptions(font: string): Ean13Options {
    return {marginLeft: 10, marginTop: 20, marginRight: 60, textMargin: -2, fontSize: 23, font, height: 42};
  }

  public getRightTextOption(font: string): BaseOptions {
    return {marginTop: 28, height: 60, textPosition: "top", textMargin: 5, fontSize: 20, font};
  }

  public setTopTextStyle(text: SVGTextElement): void {
    const font = this.getFontName();

    text.setAttribute("style", `font-family:'${font}';font-size: 17px;font-weight:normal`);
    text.setAttribute("x", '35');
    text.setAttribute("y", '20');
  }

  public getFilename(): string {
    return `barcode-${this.code.val()}.pdf`;
  }

  public getFontName(): string {
    return 'OpenSans';
  }

  public getPdfSize(): [number, number] {
    return [190, 93];
  }

  private init() {
    this.form.init(this);

    this.code.on('change', (): void => this.form.markValid(this.code));
    this.form.limitToChars(this.code, 12);

    if (this.form.isDebug()) {
      this.applyDebugValues();
      this.validate();
      this.paint();
    }
  }

  public applyDebugValues(): void {
    this.code.val('123456789012');
  }
}
