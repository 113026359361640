import * as $ from 'jquery';
import {EanDefault, EanKids, EanKarnan, IsbnKids} from './forms';
import {Form} from '.';

$(function () {
  const canvas = $('#barcode') as JQuery<HTMLElement>;
  const preview = $('#preview') as JQuery<HTMLButtonElement>;
  const invalidFormWarning = $('#invalid-form') as JQuery<HTMLParagraphElement>;
  const downloadButton = $('#download');
  const pageSelector = $('#page-selector') as JQuery<HTMLSelectElement>;

  pageSelector.on('change', function () {
    const url = pageSelector.val() as string;

    if (url) {
      window.location.href = url;
    }
  });

  const div = $('.form').get(0) as HTMLDivElement;

  if (div) {
    const form = new Form(preview, canvas, invalidFormWarning, downloadButton, div);

    const type = window.location.pathname.split('/').pop().split('.').shift();

    switch (type) {
      case 'ean-magazines':
        new EanDefault(form, canvas.get(0));
        break;
      case 'ean-kids':
        new EanKids(form, canvas.get(0));
        break;
      case 'isbn-kids':
        new IsbnKids(form, canvas.get(0));
        break;
      case 'ean-karnan':
        new EanKarnan(form, canvas.get(0));
        break;
      default:
        break;
    }
  }
});
