export const EanDefaultMagazineCodes: Record<string, string> = {
    "0065": "Bamse Läslovsspecial",
    "0144": "Wheels",
    "0167": "Min Häst",
    "0212": "Hemmets Journal",
    "0219": "Classic Motor",
    "0341": "Hälge Album",
    "0361": "Hus & Hem",
    "0370": "Asterix",
    "0509": "Humorkavalkad",
    "0556": "Hälge Presentbok",
    "0574": "Agent X-9",
    "0604": "Power",
    "0659": "Magazine Cafe",
    "0661": "Disney Prinsessor",
    "0666": "HemmetsJ Bästa Kryss",
    "0678": "Goal",
    "0687": "Kalle Ankas Pocket",
    "0703": "Egmont Mix",
    "0709": "Fantomen",
    "0712": "Pyssla med",
    "0720": "Kalle Anka & Co",
    "0723": "Måla Och Pyssla",
    "0726": "Bamse Julalbum",
    "0727": "Uti Vår Hage",
    "0733": "Disney Special",
    "0752": "Utemagasinet",
    "0754": "91:An",
    "0769": "Knasen",
    "0778": "Kalle Anka Pocket Spec",
    "0781": "Pondus",
    "0783": "Bamse",
    "0788": "Musse Piggs Deckargåtor",
    "0793": "KalleAnka & co Skollov",
    "0812": "Bamse Äventyr",
    "0822": "Barbie",
    "0831": "Auto Motor & Sport",
    "0840": "87:An Axelsson",
    "0848": "AutoMotorSport Tema",
    "0879": "Praktiskt Båtägande",
    "0880": "Hälsa",
    "0894": "EgmBilochCampingkataloger",
    "0901": "Åka Skidor",
    "0905": "Pets",
    "0917": "Hälge Julalbum",
    "0948": "Vagabond",
    "0984": "Hälge",
    "4028": "My Little Pony",
    "4069": "Husvagn & Camping",
    "4070": "Pokémon",
    "4079": "King",
    "4136": "Sudoku Frossa",
    "4144": "Bamse Extra",
    "4169": "V75 Guiden",
    "4265": "Åka Skidor Special",
    "4292": "Egmont Kids",
    "4360": "Wheels Special",
    "4398": "Aktivitetspåse",
    "4409": "Min Favorit",
    "4419": "Nära",
    "4439": "Bamse för de yngsta",
    "4441": "Djurliv",
    "4443": "HemmetsJourn.Favoriter",
    "4445": "Kalle Anka Klassiker",
    "4446": "Robot",
    "4508": "HemmetsJournal Special",
    "4539": "Scandinavian Retro",
    "4540": "Hus&Hem Extra",
    "4550": "LEGO",
    "4566": "Presentpåse",
    "4631": "Bamse Kul att Lära",
    "4667": "Kalles Deckarpocket",
    "4702": "HemmetsJournal Bästa",
    "4704": "Egmont Junior",
    "4706": "Tidningspåse",
    "4708": "Barbie Special",
    "4709": "Megapack",
    "4723": "Robot presenterar",
    "4727": "Svenska Öden & Äventyr",
    "4753": "Lego Ninjago",
    "4758": "Asterix Julalbum",
    "4763": "Kalle Anka Megapåse",
    "4769": "Kids Special",
    "4777": "Top Model",
    "4785": "Frost",
    "4786": "Svensk Golf",
    "4790": "Ankeborgs samlarpocket",
    "4798": "Knasen Julalbum",
    "4801": "LasseMajasDetektivbyrå",
    "4806": "Frost special",
    "4843": "Barnfavoriter",
    "4849": "Kryss Special",
    "4863": "Kalle Ankas Bästa",
    "4869": "Kalle Anka Önskar God Jul",
    "4876": "Egmont Sampack",
    "4881": "Brott & mysterier",
    "4901": "Marvel",
    "4917": "Megatrend",
    "4939": "Superpack",
    "4945": "Egmont Miniböcker",
    "4972": "HemmetsJournal Tema",
    "4977": "Paw Patrol",
    "4978": "Stickat & Sånt",
    "4979": "Egmont Sagor",
    "4984": "Kids Trend",
    "6005": "Aktivitetspåse",
    "6006": "Kids Trend",
    "6008": "Bamse",
    "6010": "Bamse Äventyr",
    "6011": "Barbie",
    "6012": "Barbie Special",
    "6013": "Egmont Kids",
    "6014": "Disney Prinsessor",
    "6015": "Disney Special",
    "6016": "Egmont Junior",
    "6018": "Frost",
    "6019": "Frost special",
    "6022": "Kalle Anka Klassiker",
    "6023": "Kalle Anka Megapåse",
    "6024": "Kalle Anka Pocket Spec",
    "6026": "Kids Special",
    "6028": "LasseMajasDetektivbyrå",
    "6029": "LEGO",
    "6031": "LEGO Explorer",
    "6032": "Lego Ninjago",
    "6033": "Megapack",
    "6034": "Min Favorit",
    "6035": "Min Häst",
    "6037": "My Little Pony",
    "6038": "Pets",
    "6039": "Pokémon",
    "6041": "Presentpåse",
    "6042": "Pyssla med",
    "6045": "Tidningspåse",
    "6047": "Top Model",
    "6048": "Bamse Extra",
    "6049": "Marvel",
    "6050": "Megatrend",
    "6052": "Paw Patrol",
    "6053": "Barnfavoriter",
    "6054": "Egmont Mix",
    "7404": "Prakt. Båtägande Spec",
    "7460": "Bamse Målarbok",
    "7461": "Bamse SagoMålarbok",
    "7463": "Läs & Skriv med Bamse",
    "7464": "Lär Engelska med Bamse",
    "7495": "Bamses Äventyr (Bok)",
    "7496": "Bamse träffade (Bok)",
    "7497": "Bamse Kvartettspel",
    "7498": "Bamse Gympakortspel",
}
