import * as $ from 'jquery';
import * as SVGtoPDF from "svg-to-pdfkit";
import * as blobStream from "blob-stream";
import { EanBarcode } from './EanBarcode';

const PDFDocument = require('pdfkit').default;

export class Form {

  constructor(
    public readonly previewButton: JQuery<HTMLButtonElement>,
    public readonly canvas: JQuery<HTMLElement>,
    private readonly invalidFormWarning: JQuery<HTMLParagraphElement>,
    private readonly downloadButton: JQuery<HTMLElement>,
    private readonly container: HTMLDivElement,
  ) {
  }

  public init(barcode: EanBarcode) {
    this.previewButton.on('click', () => {
      if (!barcode.validate()) {
        this.showWarning();
        console.log("Invalid input");
        return;
      }

      this.hideWarning();

      try {
        barcode.paint();
      } catch (e) {
        console.log('Paint error: ', e);
      }
    });

    this.downloadButton.on('click', () => {
      if (!barcode.validate()) {
        this.showWarning();
        console.log("Invalid input");
        return;
      }

      return this.download(barcode.getPdfSize(), barcode.getFontName(), barcode.getFilename());
    });
  }

  private validate() {
  }

  public isDebug(): boolean {
    return (new URLSearchParams(window.location.search)).has('debug');
  }

  public async download(size: [number, number], fontName: string, filename: string): Promise<void> {
    const canvas = this.canvas.get(0);

    try {
      await this.downloadPDF(canvas, size, fontName, filename);
    } catch (e) {
      console.log(e);
    }
  }

  private async downloadPDF(svg: HTMLElement, size: [number, number], fontName: string, outFileName: string) {
    const font = await fetch(`./${fontName}.ttf`);
    const fontBuffer = await font.arrayBuffer();

    let doc = new PDFDocument({ compress: false, font: fontBuffer, size });
    doc.registerFont(fontName, fontBuffer);

    SVGtoPDF(doc, svg, 0, 5, {
      fontCallback: function () {
        return fontName;
      }, colorCallback: function (color) { 
        //print color override in CMYK format instead of RGB
        if (color[0][0] == 0) {
          color[0] = [0, 0, 0, 100]
        } else {
          color[0] = [0, 0, 0, 0]
        }
        return color
      }
    });

    let stream = doc.pipe(blobStream());
    stream.on('finish', () => {
      let blob = stream.toBlob('application/pdf');
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = outFileName;
      link.click();
    });
    doc.end();
  }

  private showWarning(): void {
    this.invalidFormWarning.css('visibility', 'visible');
  }

  private hideWarning(): void {
    this.invalidFormWarning.css('visibility', 'hidden');
  }

  public get<T>(selector: string): JQuery<T> {
    return $(selector, this.container) as JQuery<T>;
  }

  public mark(element: JQuery<HTMLElement>, isInvalid: boolean): boolean {
    if (isInvalid) {
      this.markInvalid(element);
    } else {
      this.markValid(element);
    }

    return !isInvalid;
  }

  public markValid(element: JQuery<HTMLElement>, otherElement: JQuery<HTMLElement> | null = null): void {
    element.removeClass('is-invalid');
    if (otherElement) {
      otherElement.removeClass('is-invalid');
    }
  }

  public markInvalid(element: JQuery<HTMLElement>): void {
    element.addClass('is-invalid');
  }

  public getSortLocale(): string {
    const fromHtml = $('html').attr('lang');

    switch (fromHtml) {
      case 'sv':
        return 'sv-SE';
      case 'da':
        return 'da-DK';
      default:
        return 'en-US';
    }
  }

  public limitToChars(element: JQuery<HTMLInputElement>, chars: number): void {
    element.on('keypress', function (e: JQuery.KeyPressEvent<HTMLInputElement>) {
      return element.val().length < chars;
    });
  }

  public fixLeadingZero(element: JQuery<HTMLInputElement>, zeros: number = 1): void {
    const val = element.val();
    if (val.length === 2 && zeros === 2) {
      element.val('0' + val);
    } else if (val.length === 1 && zeros === 2) {
      element.val('00' + val);
    } else if (val.length === 1 && zeros === 1) {
      element.val('0' + val);
    }
  }

  public fixTrailingZero(element: JQuery<HTMLInputElement>, zeros: number = 1): void {
    const val = element.val();
    if (val.length === 2 && zeros === 2) {
      element.val(val + '0');
    } else if (val.length === 1 && zeros === 2) {
      element.val(val + '00');
    } else if (val.length === 1 && zeros === 1) {
      element.val(val + '0');
    }
  }
}
