import {EanDefault} from './EanDefault';
import {BaseOptions, Ean13Options} from 'jsbarcode';

export class EanKids extends EanDefault {
  public getFontName(): string {
    return 'Myriad';
  }

  public applyDebugValues(): void {
    this.magazineOther.val('4801');
    this.issue.val('03');
    this.price.val('049');
    this.priceOre.val('00');
    this.tidsam.get(0).checked = true;
  }

  public getBarcodeTextOptions(font: string): Ean13Options {
    return {marginTop: 30, font, height: 63, textMargin: -3, marginBottom: 40 };
  }

  public getRightTextOption(font: string): BaseOptions {
    return {marginTop: 28, height: 60, textPosition: "top", textMargin: 2, fontSize: 20, font};
  }

  public setTopTextStyle(text: SVGTextElement): void {
    const font = this.getFontName();

    text.setAttribute("style", `font-family:'${font}';font-size: 20px;font-weight:normal`);
    text.setAttribute("x", '15');
    text.setAttribute("y", '20');
  }

  public getPdfSize(): [number, number] {
    return [222, 97];
  }

}
